<template>
  <div id="app">
    <Navbar/>
    <div class="main">
      <div class="content">
        <transition name="fade-transform" mode="out-in">
          <router-view/>
        </transition>
      </div>

    </div>
    <div class="footer">
      <span>©{{ nowYear }}</span>
      <el-link target="_blank" href="https://beian.miit.gov.cn"> 黔ICP备2021008900号-1</el-link>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: 'App',
  components: {Navbar},
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: title => {
        return title ? title + ' - ' + this.app : this.app
      }
    }
  },
  data() {
    return {
      nowYear: new Date().getFullYear(),
      size: {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resize)
    this.$store.dispatch('app/changeSetting', {key: 'mobile', value: this.mobile})
  },
  computed: {
    title() {
      return this.$t(this.$route.meta.title)
    },
    app() {
      return this.$t('info.name')
    },
    mobile() {
      return this.size.width <= 992
    }
  },
  methods: {
    resize() {
      this.size.width = window.innerWidth
      this.size.height = window.innerHeight
      this.$store.dispatch('app/changeSetting', {key: 'size', value: this.size})
      this.$store.dispatch('app/changeSetting', {key: 'mobile', value: this.mobile})
    },
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: auto;
  position: relative;
  height: 100vh;

  .main {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    //height: calc(100vh - 61px);

    .content {
      min-height: calc(100vh - 160px);
    }
  }

  .header {
    position: sticky;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    margin: 0 !important;
    height: 60px;
    top: 0;
    z-index: 1;
    background-color: #fff;

    .right {
      text-align: right;
      line-height: 60px;
      padding-right: 30px !important;
    }

    .left {
      text-align: left;
      line-height: 60px;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    z-index: 1;
    width: 100%;
    padding: 20px 20px 0 20px;
    text-align: center;

    > * {
      padding: 0 10px;
    }
  }

}

.el-menu.el-menu--horizontal {
  border: none !important;
}

.el-menu {
  .el-menu-item {
    border: none !important;
  }
}

</style>
