export default {
  menu: {
    index: 'Home',
    privacyPolicy: 'Privacy Policy',
    registerPolicy: 'User Register Policy',
    supports: 'Supports',
  },
  info: {
    name: 'I Like Drawing',
    desc1: 'A special painting tool for children',
    desc2: 'Release your child\'s nature and cultivate your child\'s interests from an early age.',
    download: 'Free Download',
    version: 'Version',
    slogan: {
      t1: 'Drawing is the best way for children to express themselves and release pressure',
      t2: 'It\'s a good illustration, ',
      t3: 'What we draw is not only a picture, but a \tbeautiful story in our hearts.',
    }
  }
}
