import Router from 'vue-router'
import Vue from 'vue'

Vue.use(Router)

export const constantRoutes = [
    {
        path: '',
        component: () => import('@/views/index/index'),
        meta: {title: 'menu.index'}
    },
    {
        path: '/privacyPolicy',
        component: () => import('@/views/privacyPolicy/index'),
        meta: {title: 'menu.privacyPolicy'}
    },
    {
        path: '/registerPolicy',
        component: () => import('@/views/registerPolicy/index'),
        meta: {title: 'menu.registerPolicy'}
    },
    {
        path: '/supports',
        component: () => import('@/views/supports/index'),
        meta: {title: 'menu.supports'}
    },
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
    },
]


export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})
