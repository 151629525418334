const state = {
    title: '绘画启蒙',
    size: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    mobile: false
}

const mutations = {
    CHANGE_SETTING: (state, {key, value}) => {
        if (state.hasOwnProperty(key)) {
            state[key] = value
        }
    }
}

const actions = {
    // 修改布局设置
    changeSetting({commit}, data) {
        commit('CHANGE_SETTING', data)
    },
    // 设置网页标题
    setTitle({commit}, title) {
        state.title = title
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
