export default {
    menu: {
        index: '首页',
        privacyPolicy: '隐私政策',
        registerPolicy: '用户注册政策',
        supports: '技术支持',
    },
    info: {
        name: '绘画启蒙',
        desc1: '一款专用于儿童的绘画工具',
        desc2: '释放孩子的天性，从小培养孩子的兴趣。',
        download: '免费下载',
        version: '版本',
        slogan: {
            t1: '画画是孩子们表达自己，释放压力最好的方式',
            t2: '很好的诠释了：',
            t3: '我们画的不止是画，是心中美好的故事',
        }
    }
}
